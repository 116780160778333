<template>
  <ProfilePageLayout class="personalArea" :sections="sections" :show-save="showSave" @user:save="updateUser">
    <template #companyInfo>
      <p class="personalArea__title">{{ $t('COMPANY.COMPANY_INFO') }}</p>
      <BaseInput v-model="newUser.company_name" class="fs-12" :label="$t('COMPANY.NAME')" />
      <BaseSelect v-model="newUser.industry" :label="$t('COMPANY.INDUSTRY')" :options="industryOptions" />
      <BaseSelect v-model="country" :label="$t('COMPANY.COUNTRY')" :options="countryOptions" />
      <BaseLocation
        v-model="newUser.main_location"
        :style="{ 'background-color': '#ffffff', height: '40px', padding: '6px 12px', 'font-size': '14px' }"
        :placeholder="$t('COMPANY.MAIN_LOCATION')"
        :country="newUser.country"
        :key="newUser.country"
      />
    </template>
    <template #contactInfo>
      <p class="personalArea__title">{{ $t('POINT_OF_CONTACT') }}</p>
      <BaseInput v-model="newUser.contact_name" class="fs-12" :label="$t('PERSONAL_DATA.NAME')" />
      <BaseInput v-model="newUser.contact_email" class="fs-12" :label="$t('PERSONAL_DATA.EMAIL')" />
      <BaseInput v-model="newUser.contact_phone" class="fs-12" :label="$t('PERSONAL_DATA.PHONE')" />
    </template>
    <template #accountDetails>
      <p class="personalArea__title">{{ $t('ACCOUNT_DETAILS') }}</p>
      <BaseInput v-model="newUser.name" class="fs-12" :label="$t('PERSONAL_DATA.NAME')" />
      <BaseInput v-model="newUser.email" disabled class="fs-12" :label="$t('PERSONAL_DATA.EMAIL')" />
    </template>
    <template #personalInfo>
      <p class="personalArea__title">{{ $t('PERSONAL_INFO') }}</p>
      <BaseInput v-model="newUser.name" class="fs-12" :label="$t('PERSONAL_DATA.NAME')" />
      <BaseInput v-model="newUser.email" disabled class="fs-12" :label="$t('PERSONAL_DATA.EMAIL')" />
      <BaseInput v-model="newUser.phone" class="fs-12" :label="$t('PERSONAL_DATA.PHONE')" />
      <BaseCalendarInput v-model="newUser.birthdate" use-age-filter class="fs-12" :label="$t('PROFILE.DATE_OF_BIRTH')" />
      <BaseLocation v-model="newUser.main_location" class="fs-12 mt-20 pa-10" type="(cities)" :placeholder="$t('PERSONAL_DATA.LOCATION')" />
      <hr class="hr mv-20" data-theme="light" />
      <BaseInput v-if="newUser.details" v-model="newUser.details.portfolio_link" class="fs-12" :label="$t('PROFILE.PORTFOLIO_LINK')" />
    </template>
    <template #equipment>
      <p class="personalArea__title">{{ $t('EQUIPMENT') }}</p>
      <ManageEquipment :equipment="equipment" :user="user" @equipment:update="handleEquipmentUpdate" />
      <div class="personalArea__studio">
        <p class="personalArea">{{ $t('PHOTO_STUDIO_ACCESS') }}</p>
        <div v-if="newUser.details">
          <div
            v-for="(answer, index) in answerOwnStudio"
            :key="index"
            :class="['personalArea__ownstudio-option', { 'personalArea__ownstudio-option--is-selected': newUser.details.ownStudio === answer }]"
            @click="setOwnStudio(answer)"
          >
            {{ answer }}
          </div>
        </div>
      </div>
    </template>
    <template #language>
      <p class="personalArea__title">{{ $t('LANGUAGE') }}</p>
      <BaseSelect v-model="newUser.english_level" class="fs-12" :clearable="false" :options="languageLevelOptions" :label="$t('PROFILE.ENGLISH_LEVEL')" />
      <BaseSelect v-model="newUser.arabic_level" class="fs-12" :clearable="false" :options="languageLevelOptions" :label="$t('PROFILE.ARABIC_LEVEL')" />
    </template>
  </ProfilePageLayout>
</template>

<script>
// Api
import { UserApi } from '@api/index';

// Store
import { mapGetters } from 'vuex';

// Base components
import BaseCalendarInput from '@base/BaseCalendarInput.vue';
import BaseInput from '@base/BaseInput.vue';
import BaseLocation from '@base/BaseLocation.vue';
import BaseSelect from '@base/BaseSelect.vue';

// Components
import ManageEquipment from '@components/photographer/ManageEquipment.vue';

// Layout
import ProfilePageLayout from '@layout/ProfilePageLayout.vue';

// Utils
import countriesHelper from '@utils/countries/countries';

/**
 * Component used to show the user personal area based on role
 */
export default {
  name: 'PersonalArea',
  components: {
    BaseCalendarInput,
    BaseInput,
    BaseLocation,
    BaseSelect,
    ManageEquipment,
    ProfilePageLayout
  },
  data() {
    return {
      answerOwnStudio: ['yes', 'no'],
      countryOptions: countriesHelper.getGroupedContries(),
      industryOptions: [
        this.$t('INDUSTRY_LIST.AGRICULTURE'),
        this.$t('INDUSTRY_LIST.CONSTRUCTION'),
        this.$t('INDUSTRY_LIST.CORPORATE'),
        this.$t('INDUSTRY_LIST.E-COMMERCE'),
        this.$t('INDUSTRY_LIST.EDUCATION'),
        this.$t('INDUSTRY_LIST.EVENTS_WEDDINGS'),
        this.$t('INDUSTRY_LIST.FOOD_DELIVERY'),
        this.$t('INDUSTRY_LIST.FOOD_PRODUCTION'),
        this.$t('INDUSTRY_LIST.FOOD_TECH'),
        this.$t('INDUSTRY_LIST.GOVERNMENT'),
        this.$t('INDUSTRY_LIST.HEALTHCARE'),
        this.$t('INDUSTRY_LIST.HOSPITALITY'),
        this.$t('INDUSTRY_LIST.MANIFACTURING'),
        this.$t('INDUSTRY_LIST.PUBLIC_ADMINISTRATION'),
        this.$t('INDUSTRY_LIST.REAL_ESTATE'),
        this.$t('INDUSTRY_LIST.SOCIAL_MEDIA'),
        this.$t('INDUSTRY_LIST.SPORTS_FITNESS'),
        this.$t('INDUSTRY_LIST.TRANSPORTATION'),
        this.$t('INDUSTRY_LIST.WHOLESALE'),
        this.$t('INDUSTRY_LIST.INDIVIDUAL'),
        this.$t('INDUSTRY_LIST.OTHER')
      ],
      languageLevelOptions: [
        this.$t('LANGUAGE_LEVEL.NATIVE'),
        this.$t('LANGUAGE_LEVEL.FLUENT'),
        this.$t('LANGUAGE_LEVEL.INTERMEDIATE'),
        this.$t('LANGUAGE_LEVEL.BEGINNER'),
        this.$t('LANGUAGE_LEVEL.NONE')
      ],
      oldUser: {},
      newUser: {}
    };
  },
  computed: {
    ...mapGetters({
      isClient: 'user/isClient',
      user: 'user/getUser'
    }),
    /**
     * Returns flattened country items
     */
    countryFlattened() {
      const labelsList = this.countryOptions.map(({ labels }) => labels);
      const flattenedLabelsList = labelsList.flat();

      return flattenedLabelsList;
    },
    /**
     * Retrieving country item
     */
    country: {
      get() {
        if (!this.newUser.country) return {};

        const item = this.countryFlattened.find(({ value }) => value.toLowerCase() === this.newUser.country.toLowerCase());

        return item;
      },
      set(value) {
        this.$set(this.newUser, 'country', value.value);
      }
    },
    /**
     * Return user equipment
     */
    equipment() {
      // Retrieving details from  user object (fallback to empty object if undefined)
      const { details = {} } = this.newUser;
      // Retrieving equipment from details (fallback to empty object if undefined)
      const { equipment = {} } = details;

      return equipment;
    },
    /**
     * Check if any change has been made
     */
    isUserEdited() {
      return JSON.stringify(this.user) !== JSON.stringify(this.newUser);
    },
    /**
     * Returning sections based on role
     */
    sections() {
      if (this.isClient) return [{ name: 'companyInfo' }, { name: 'contactInfo' }, { name: 'accountDetails' }];

      return [{ name: 'personalInfo' }, { name: 'equipment' }, { name: 'language' }];
    },

    /**
     * True if some field has changed
     */
    showSave() {
      return JSON.stringify(this.oldUser) !== JSON.stringify(this.newUser);
    }
  },
  async created() {
    const { id, role } = this.user;

    const userResource = await UserApi.getUser({ id, role });

    if (userResource.statusCode === 200) {
      this.newUser = JSON.parse(JSON.stringify(userResource.data.user));
      this.oldUser = JSON.parse(JSON.stringify(userResource.data.user));
    }
  },
  methods: {
    /**
     * Method used to update the equipments
     */
    handleEquipmentUpdate({ selectedCategory, value }) {
      this.$set(this.newUser.details.equipment, selectedCategory, value);
    },
    setOwnStudio(value) {
      this.$set(this.newUser.details, 'ownStudio', value);
    },
    /**
     * Method used to save the user update
     */
    async updateUser() {
      const { id } = this.user;
      delete this.newUser.status;

      const updateUserResource = await UserApi.updateUser({ id, payload: this.newUser });

      if (updateUserResource.statusCode === 200) {
        this.$store.dispatch('notification/addSuccessNotification', this.$t('USER_UPDATED'));
        this.oldUser = JSON.parse(JSON.stringify(this.newUser));
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.personalArea {
  &__title {
    @include inter-font($size: 16px, $bolded: true);
    margin-bottom: 20px;
    text-align: left;
  }
  &__studio {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
    text-align: left;
    > p {
      margin-bottom: 0;
    }
    > div {
      display: flex;
    }
  }
  &__ownstudio-option {
    align-items: center;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    height: 35px;
    @include inter-font($size: 12px, $color: var(--main-text-color));
    justify-content: center;
    min-width: 40px;
    text-transform: uppercase;

    &:nth-of-type(2) {
      margin-left: 10px;
    }

    &--is-selected {
      background-color: var(--main-text-color);
      color: var(--terziary-text-color);
    }
  }
}
</style>

<style lang="scss">
.personalArea {
  @include responsive($max: md) {
    height: fit-content !important;
  }
  .baseLocation {
    background-color: transparent !important;
    height: 40px;
  }
}
</style>
