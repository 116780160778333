<template>
  <BaseDropdown ref="baseDropdown">
    <template #trigger>
      <q-field class="baseCalendarInput__field pa-0" :error="error" clearble dense outlined stack-label>
        <template #control>
          <span :class="['baseCalendarInput__control', { baseCalendarInput__date: formatted_value }]">{{ formatted_value || getLabel }}</span>
        </template>
        <template #append>
          <BaseIcon icon="calendar" :custom_style="{ cursor: 'pointer', height: '16px', width: '16px' }" />
        </template>
      </q-field>
    </template>
    <template #menu>
      <BaseCalendar :default-view="!value ? 'Years' : 'Calendar'" :defaultDate="calendarFormattedValue" :range="range" :options="options" @new-date="handleDateUpdate" />
    </template>
  </BaseDropdown>
</template>

<script>
import { date } from 'quasar';

// Base Components
import BaseCalendar from '@base/BaseCalendar.vue';
import BaseDropdown from '@base/BaseDropdown.vue';
import BaseIcon from '@base/BaseIcon.vue';

export default {
  name: 'BaseCalendarInput',
  model: {
    event: 'update:value',
    prop: 'value'
  },
  components: {
    BaseCalendar,
    BaseDropdown,
    BaseIcon
  },
  props: {
    /**
     * Error
     */
    error: { default: false, type: Boolean },
    /**
     * Input label
     */
    label: { default: '', type: String },
    /**
     * Set to true if the field is mandatory
     */
    mandatory: { default: false, type: Boolean },
    /**
     * Set to true if a range of data selection is needed
     */
    range: { default: false, type: Boolean },
    /**
     * Date selected
     */
    value: { default: null, type: [Object, String] },
    /**
     * Set to true to allow only 21 > years old selection
     */
    useAgeFilter: { default: false, type: Boolean }
  },
  computed: {
    formatted_value() {
      if (!this.value) return '';
      if (this.value.from) return `${date.formatDate(this.value.from, 'DD-MM-YYYY')} - ${date.formatDate(this.value.to, 'DD-MM-YYYY')}`;

      return date.formatDate(this.value, 'DD-MM-YYYY');
    },
    /**
     * Returning the label with * if its mandatory
     */
    getLabel() {
      if (this.mandatory) return `${this.label}*`;
      return this.label;
    },
    calendarFormattedValue() {
      return date.formatDate(this.value, 'DD/MM/YYYY');
    }
  },
  methods: {
    /**
     * Method used to handle when the user confirm a date, it will emit the value and close the dropdown
     */
    handleDateUpdate($event) {
      this.$emit('update:value', $event);
      this.$refs.baseDropdown.toggleMenu();
    },
    options(date) {
      if (this.useAgeFilter) return this.setPhotographerAging(date);
      return true;
    }
  }
};
</script>

<style lang="scss">
.baseCalendarInput {
  &__field {
    .q-field__control {
      border-radius: 10px;
      color: var(--main-text-color) !important;
      &::before {
        border: 1px solid var(--form-fields-border-color);
      }
    }
  }
  &__control {
    color: var(--secondary-text-color);
  }
  &__date {
    color: var(--main-text-color);
  }
}
.baseDropdown__menu.q-position-engine {
  max-height: unset !important;
}
</style>
