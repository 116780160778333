const equipments = [
  {
    category: {
      label: 'Camera',
      value: 'CAMERA'
    },
    items: [
      {
        brand: 'canon',
        models: [
          'Canon EOS-1D',
          'Canon EOS-1D Mark II',
          'Canon EOS-1D Mark III',
          'Canon EOS-1D Mark IV',
          'Canon EOS-1Ds',
          'Canon EOS-1Ds Mark II',
          'Canon EOS-1Ds Mark III',
          'Canon EOS-1D X',
          'Canon EOS-1D X II',
          'Canon EOS-1D X Mark III',
          'Canon EOS 5D',
          'Canon EOS 5D Mark II',
          'Canon EOS 5D Mark III',
          'Canon EOS 5D Mark IV',
          'Canon EOS 5D SR',
          'Canon EOS 6D',
          'Canon EOS 6D Mark II',
          'Canon EOS 7D',
          'Canon EOS 7D Mark II',
          'Canon EOS 10D',
          'Canon EOS 20D',
          'Canon EOS 30D',
          'Canon EOS 40D',
          'Canon EOS 50D',
          'Canon EOS 60D',
          'Canon EOS 70D',
          'Canon EOS 77D',
          'Canon EOS 80D',
          'Canon EOS D30',
          'Canon EOS D60',
          'Canon EOS 300D',
          'Canon EOS 350D',
          'Canon EOS 400D',
          'Canon EOS 450D',
          'Canon EOS 500D',
          'Canon EOS 550D',
          'Canon EOS 600D',
          'Canon EOS 650D',
          'Canon EOS 700D',
          'Canon EOS 100D',
          'Canon EOS 200D',
          'Canon EOS 750D',
          'Canon EOS 760D',
          'Canon EOS 800D',
          'Canon EOS 1000D',
          'Canon EOS 1100D',
          'Canon EOS 1200D',
          'Canon EOS 1300D',
          'Canon EOS 2000D',
          'Canon EOS 4000D',
          'Canon EOS M',
          'Canon EOS M2',
          'Canon EOS M3',
          'Canon EOS M5',
          'Canon EOS M6',
          'Canon EOS M10',
          'Canon EOS M50',
          'Canon EOS M100',
          'Canon EOS R',
          'Canon EOS R5',
          'Canon EOS R6',
          'Canon PowerShot A450',
          'Canon PowerShot A460',
          'Canon PowerShot A470',
          'Canon PowerShot A480',
          'Canon PowerShot A530',
          'Canon PowerShot A540',
          'Canon PowerShot A550',
          'Canon PowerShot A560',
          'Canon PowerShot A570 IS',
          'Canon PowerShot A590 IS',
          'Canon PowerShot A610',
          'Canon PowerShot A620',
          'Canon PowerShot A630',
          'Canon PowerShot A640',
          'Canon PowerShot A650 IS',
          'Canon PowerShot A700',
          'Canon PowerShot A710 IS',
          'Canon PowerShot A720 IS',
          'Canon PowerShot A1100 IS',
          'Canon PowerShot A2000 IS',
          'Canon PowerShot G1',
          'Canon PowerShot G2',
          'Canon PowerShot G3',
          'Canon PowerShot G5',
          'Canon PowerShot G6',
          'Canon PowerShot G7',
          'Canon PowerShot G9',
          'Canon PowerShot G10',
          'Canon PowerShot G11',
          'Canon PowerShot G12',
          'Canon PowerShot G15',
          'Canon PowerShot G16',
          'Canon PowerShot G1 X',
          'Canon PowerShot G1 X Mark II',
          'Canon PowerShot G3 X',
          'Canon PowerShot G7 X',
          'Canon PowerShot G7 X Mark III',
          'Canon PowerShot G9 X',
          'Canon PowerShot Pro1',
          'Canon PowerShot Pro90 IS',
          'Canon PowerShot S2 IS',
          'Canon PowerShot S3 IS',
          'Canon PowerShot S5 IS',
          'Canon PowerShot S30',
          'Canon PowerShot S60',
          'Canon PowerShot S70',
          'Canon PowerShot S90',
          'Canon PowerShot S95',
          'Canon PowerShot S100',
          'Canon PowerShot S110',
          'Canon PowerShot S120',
          'Canon PowerShot SD30',
          'Canon PowerShot SD300',
          'Canon PowerShot SD400',
          'Canon PowerShot SD450',
          'Canon PowerShot SD500',
          'Canon PowerShot SD550',
          'Canon PowerShot SD600',
          'Canon PowerShot SD630',
          'Canon PowerShot SD700 IS',
          'Canon PowerShot SD750',
          'Canon PowerShot SD780 IS',
          'Canon PowerShot SD790 IS',
          'Canon PowerShot SD800 IS',
          'Canon PowerShot SD850 IS',
          'Canon PowerShot SD870 IS',
          'Canon PowerShot SD880 IS',
          'Canon PowerShot SD890 IS',
          'Canon PowerShot SD950 IS',
          'Canon PowerShot SD990 IS',
          'Canon PowerShot SD1000',
          'Canon PowerShot SD1100 IS',
          'Canon PowerShot SX 1 IS',
          'Canon PowerShot SX10 IS',
          'Canon PowerShot SX20 IS',
          'Canon PowerShot SX50 HS',
          'Canon PowerShot SX100 IS',
          'Canon PowerShot SX110 IS',
          'Canon PowerShot SX130 IS',
          'Canon PowerShot SX200 IS',
          'Canon PowerShot TX1'
        ]
      },
      {
        brand: 'exilim',
        models: ['Exilim EX-ZR700', 'Exilim EX-ZR1000', 'Exilim EX-100']
      },
      {
        brand: 'fujifilm',
        models: [
          'Fujifilm FinePix IS-Pro',
          'Fujifilm FinePix S1 Pro',
          'Fujifilm FinePix S2 Pro',
          'Fujifilm FinePix S3 Pro',
          'Fujifilm FinePix S5 Pro',
          'Fujifilm FinePix S6500fd',
          'Fujifilm FinePix S9100/9600',
          'Fujifilm FinePix S9000/9500',
          'Fujifilm FinePix 20 Pro',
          'Fujifilm FinePix F700',
          'Fujifilm FinePix F710',
          'Fujifilm FinePix E550',
          'Fujifilm FinePix E900',
          'Fujifilm FinePix F550EXR',
          'Fujifilm FinePix F600EXR',
          'Fujifilm FinePix F810',
          'Fujifilm FinePix HS10',
          'Fujifilm FinePix HS20EXR',
          'Fujifilm FinePix HS35EXR',
          'Fujifilm FinePix HS30EXR',
          'Fujifilm FinePix S100FS',
          'Fujifilm FinePix S200EXR',
          'Fujifilm FinePix S5100/5500',
          'Fujifilm FinePix S5200/5600',
          'Fujifilm FinePix S7000',
          'Fujifilm FinePix HS50EXR',
          'Fujifilm FinePix X10',
          'Fujifilm X100 series',
          'Fujifilm FinePix X-S1',
          'Fujifilm X-Pro1',
          'Fujifilm X-E1',
          'Fujifilm X-E2',
          'Fujifilm X-M1',
          'Fujifilm X-A1',
          'Fujifilm X-S10',
          'Fujifilm X-A2',
          'Fujifilm X-T1',
          'Fujifilm X-T10',
          'Fujifilm X-Pro2',
          'Fujifilm X-T2',
          'Fujifilm X-T20',
          'Fujifilm X-T3',
          'Fujifilm X-T30',
          'Fujifilm X100F',
          'Fujifilm X-H1',
          'Fujifilm X-T100',
          'Fujifilm XQ1',
          'Fujifilm X-T4'
        ]
      },
      {
        brand: 'hasselblad',
        models: [
          'Hasselblad',
          'Hasselblad H series',
          'Hasselblad CF series',
          'Hasselblad CFV series',
          'Hasselblad Lunar',
          'Hasselblad Stellar ',
          'Hasselblad Stellar II ',
          'Hasselblad HV  ',
          'Hasselblad Lusso '
        ]
      },
      {
        brand: 'kodak',
        models: [
          'Kodak P712',
          'Kodak P850',
          'Kodak P880',
          'Kodak C603/C643',
          'Kodak C713 ',
          'Kodak DCS-620',
          'Kodak DCS-720, -760 ',
          'Kodak DCS-14n',
          'Kodak DCS Pro SLR/n',
          'Kodak DCS Pro SLR/c',
          'Kodak Z1015IS',
          'Kodak EasyShare Z980',
          'Kodak EasyShare Z990',
          'Kodak PixPro S1'
        ]
      },
      {
        brand: 'konica',
        models: [
          'Konica Digital Revio KD-400Z',
          'Konica Revio KD-410Z',
          'Konica Revio KD-420Z',
          'Konica Digital Revio KD-500Z ',
          'Konica Revio KD-510Z',
          'Konica Minolta DiMAGE A2 (2720) ',
          'Konica Minolta DiMAGE A200 (2747) ',
          'Konica Minolta Dynax 5D ',
          'Konica Minolta Maxxum 5D ',
          'Konica Minolta α-5 Digital ',
          'Konica Minolta α Sweet Digital (2186) ',
          'Konica Minolta Dynax 7D ',
          'Konica Minolta Maxxum 7D',
          'Konica Minolta α-7 Digital (2181) ',
          'Konica Minolta DiMAGE G530 (2736) ',
          'Konica Minolta DiMAGE G600 (2744) ',
          'Konica Minolta DiMAGE Z2 (2725, SX745) '
        ]
      },
      {
        brand: 'contax',
        models: ['Contax N Digital']
      },
      {
        brand: 'leaf',
        models: ['Leaf Digital Backs']
      },
      {
        brand: 'leica',
        models: [
          'Leica M8',
          'Leica M8.2',
          'Leica M9',
          'Leica Digilux 2',
          'Leica Digilux 3',
          'Leica V-LUX 1',
          'Leica D-LUX 3',
          'Leica D-LUX 4',
          'Leica D-LUX 5',
          'Leica Digital Modul-R',
          'Leica X1',
          'Leica X2',
          'Leica X Vario'
        ]
      },
      {
        brand: 'minolta',
        models: [
          'Minolta RD-175',
          'Minolta DiMAGE 5',
          'Minolta DiMAGE 7 (2766)',
          'Minolta DiMAGE 7i (2779)',
          'Minolta DiMAGE 7Hi (2778)',
          'Minolta DiMAGE 7UG',
          'Minolta DiMAGE A1 (2782)',
          'Minolta DiMAGE G400 (2732)',
          'Minolta DiMAGE G500 (2731)'
        ]
      },
      {
        brand: 'nikon',
        models: [
          'Nikon D1',
          'Nikon D1H',
          'Nikon D1X',
          'Nikon D2H',
          'Nikon D2Hs',
          'Nikon D2X',
          'Nikon D2Xs',
          'Nikon D3',
          'Nikon D3S',
          'Nikon D3X',
          'Nikon D4',
          'Nikon D4S',
          'Nikon D40',
          'Nikon D40x',
          'Nikon D50',
          'Nikon D60',
          'Nikon D70',
          'Nikon D70s',
          'Nikon D80',
          'Nikon D90',
          'Nikon D100',
          'Nikon D200',
          'Nikon D300',
          'Nikon D300s',
          'Nikon D500',
          'Nikon D600',
          'Nikon D610',
          'Nikon D700',
          'Nikon D750',
          'Nikon D800',
          'Nikon D800E',
          'Nikon D810',
          'Nikon D810A',
          'Nikon D850',
          'Nikon D3000',
          'Nikon D3100',
          'Nikon D3200',
          'Nikon D3300',
          'Nikon D5000',
          'Nikon D5100',
          'Nikon D5200',
          'Nikon D5300',
          'Nikon D5500',
          'Nikon D5600',
          'Nikon D7000',
          'Nikon D7100',
          'Nikon D7200',
          'Nikon D7500',
          'Nikon 1 J1',
          'Nikon 1 V1',
          'Nikon 1 J2',
          'Nikon 1 V2',
          'Nikon 1 J3',
          'Nikon 1 V3',
          'Nikon 1 J4',
          'Nikon 1 J5',
          'Nikon 1 S1',
          'Nikon 1 S2',
          'Nikon 1 AW1',
          'Nikon Z 6',
          'Nikon Z 6II',
          'Nikon Z 7',
          'Nikon Z 7 II',
          'Nikon Z fc',
          'Nikon Coolpix A',
          'Nikon Coolpix P6000',
          'Nikon Coolpix P7000',
          'Nikon Coolpix P7100',
          'Nikon Coolpix P7700',
          'Nikon Coolpix P7800',
          'Nikon Coolpix P330',
          'Nikon Coolpix P340',
          'Nikon Coolpix 700 ',
          'Nikon Coolpix 800 ',
          'Nikon Coolpix 880 ',
          'Nikon Coolpix 900 ',
          'Nikon Coolpix 950 ',
          'Nikon Coolpix 990 ',
          'Nikon Coolpix 995 ',
          'Nikon Coolpix 2100 ',
          'Nikon Coolpix 2500 ',
          'Nikon Coolpix 3200 ',
          'Nikon Coolpix 3700 ',
          'Nikon Coolpix 4300 ',
          'Nikon Coolpix 4500 ',
          'Nikon Coolpix 5000 ',
          'Nikon Coolpix 5400',
          'Nikon Coolpix 5700',
          'Nikon Coolpix 8400',
          'Nikon Coolpix 8700',
          'Nikon Coolpix 8800',
          'Nikon Coolpix S6 '
        ]
      },
      {
        brand: 'olympus',
        models: [
          'Olympus C-5050Z',
          'Olympus C-5060WZ',
          'Olympus C-8080WZ',
          'Olympus C-7000',
          'Olympus E-1',
          'Olympus E-3',
          'Olympus E-5',
          'Olympus E-10',
          'Olympus E-20',
          'Olympus E-30',
          'Olympus E-300',
          'Olympus E-330',
          'Olympus E-400',
          'Olympus E-410',
          'Olympus E-420',
          'Olympus E-450',
          'Olympus E-500',
          'Olympus E-510',
          'Olympus E-520',
          'Olympus E-600',
          'Olympus E-620',
          'Olympus OM-D E-M1',
          'Olympus OM-D E-M5',
          'Olympus OM-D E-M5 II',
          'Olympus OM-D E-M10',
          'Olympus PEN E-P1',
          'Olympus PEN E-P2',
          'Olympus PEN E-P3',
          'Olympus PEN E-P5',
          'Olympus PEN E-PL1',
          'Olympus PEN E-PL2',
          'Olympus PEN E-PL3',
          'Olympus PEN E-PL5',
          'Olympus PEN E-PM1',
          'Olympus PEN E-PM2',
          'Olympus SP-320',
          'Olympus SP-350',
          'Olympus SP-510 UZ',
          'Olympus SP-570 UZ',
          'Olympus Stylus 1',
          'Olympus Stylus 1S',
          'Olympus TG-4',
          'Olympus TG-5',
          'Olympus XZ-1',
          'Olympus XZ-2',
          'Olympus XZ-10'
        ]
      },
      {
        brand: 'panasonic',
        models: [
          'Panasonic Lumix DMC-FX150',
          'Panasonic Lumix DMC-FZ8',
          'Panasonic Lumix DMC-FZ18',
          'Panasonic Lumix DMC-FZ28',
          'Panasonic Lumix DMC-FZ30',
          'Panasonic Lumix DMC-FZ35',
          'Panasonic Lumix DMC-FZ38',
          'Panasonic Lumix DMC-FZ50',
          'Panasonic Lumix DMC-FZ72',
          'Panasonic Lumix DMC-FZ100',
          'Panasonic Lumix DMC-FZ150',
          'Panasonic Lumix DMC-FZ200',
          'Panasonic Lumix DMC-FZ300',
          'Panasonic Lumix DMC-FZ1000',
          'Panasonic Lumix DMC-L1',
          'Panasonic Lumix DMC-L10',
          'Panasonic Lumix DMC-LC1',
          'Panasonic Lumix DMC-GM1',
          'Panasonic Lumix DMC-GM5',
          'Panasonic Lumix DMC-G1',
          'Panasonic Lumix DMC-G2',
          'Panasonic Lumix DMC-G3',
          'Panasonic Lumix DMC-G5',
          'Panasonic Lumix DMC-G6',
          'Panasonic Lumix DMC-G7',
          'Panasonic Lumix DMC-GF1',
          'Panasonic Lumix DMC-GF2',
          'Panasonic Lumix DMC-GF3',
          'Panasonic Lumix DMC-GX1',
          'Panasonic Lumix DMC-GX7',
          'Panasonic Lumix DMC-GX8',
          'Panasonic Lumix DMC-GX85',
          'Panasonic Lumix DMC-GH1',
          'Panasonic Lumix DMC-GH2',
          'Panasonic Lumix DMC-GH3',
          'Panasonic Lumix DMC-GH4',
          'Panasonic Lumix DC-GH5',
          'Panasonic Lumix DC-GH5S',
          'Panasonic Lumix DMC-LX1',
          'Panasonic Lumix DMC-LX2',
          'Panasonic Lumix DMC-LX3',
          'Panasonic Lumix DMC-LX5',
          'Panasonic Lumix DMC-LX7',
          'Panasonic Lumix S5'
        ]
      },
      {
        brand: 'pentax',
        models: [
          'Pentax *ist D',
          'Pentax *ist DL',
          'Pentax *ist DL2',
          'Pentax *ist DS',
          'Pentax *ist DS2',
          'Pentax 645D IR',
          'Pentax 645D',
          'Pentax 645Z ',
          'Pentax K-01',
          'Pentax K100D Super',
          'Pentax K100D',
          'Pentax K10D',
          'Pentax K110D',
          'Pentax K2000/K-m',
          'Pentax K200D',
          'Pentax K20D',
          'Pentax K-1 ',
          'Pentax K-1 II',
          'Pentax K-3',
          'Pentax K-3 II',
          'Pentax K-3 III',
          'Pentax K-30',
          'Pentax K-5 II',
          'Pentax K-5 IIs',
          'Pentax K-5',
          'Pentax K-50',
          'Pentax K-500',
          'Pentax K-7',
          'Pentax K-r',
          'Pentax K-S1',
          'Pentax K-S2',
          'Pentax K-x',
          'Pentax MX-1',
          'Pentax Q',
          'Pentax Q10',
          'Pentax Q7',
          'Pentax Q-S1'
        ]
      },
      {
        brand: 'phase',
        models: ['Phase One']
      },
      {
        brand: 'polariod',
        models: ['Polaroid', 'Polaroid x530']
      },
      {
        brand: 'ricoh',
        models: ['Ricoh Caplio GX100', 'Ricoh GX200', 'Ricoh GR', 'Ricoh GR Digital', 'Ricoh GR Digital II', 'Ricoh GR Digital III', 'Ricoh GR Digital IV']
      },
      {
        brand: 'samsung',
        models: [
          'Samsung GX-10',
          'Samsung GX-20',
          'Samsung Pro815',
          'Samsung NX-M',
          'Samsung NX1',
          'Samsung NX500',
          'Samsung NX1000',
          'Samsung NX100',
          'Samsung NX200',
          'Samsung NX300',
          'Samsung NX30',
          'Samsung NX20',
          'Samsung NX10',
          'Samsung WB5000',
          'Samsung EX1',
          'Samsung EX2F'
        ]
      },
      {
        brand: 'sigma',
        models: [
          'Sigma DP1',
          'Sigma DP1s',
          'Sigma DP1x',
          'Sigma DP2',
          'Sigma DP2s',
          'Sigma DP2x',
          'Sigma FP',
          'Sigma SD9',
          'Sigma SD10',
          'Sigma SD14',
          'Sigma SD15',
          'Sigma SD1 Merrill',
          'Sigma DP1 Merrill',
          'Sigma DP2 Merrill',
          'Sigma DP3 Merrill',
          'Sigma DP0 Quattro',
          'Sigma DP1 Quattro',
          'Sigma DP2 Quattro',
          'Sigma DP3 Quattro',
          'Sigma SD Quattro',
          'Sigma SD Quattro H'
        ]
      },
      {
        brand: 'sony',
        models: [
          'Sony A1',
          'Sony a6000',
          'Sony a6100',
          'Sony a6200',
          'Sony a6300',
          'Sony a6400',
          'Sony a6500',
          'Sony a6600',
          'Sony a7 II',
          'Sony a7 III',
          'Sony a7 IV',
          'Sony a7',
          'Sony a7R II',
          'Sony a7R IV',
          'Sony a7R V',
          'Sony a7R',
          'Sony a7S II',
          'Sony a7S',
          'Sony a9',
          'Sony DSLR-A100',
          'Sony DSLR-A200',
          'Sony DSLR-A230',
          'Sony DSLR-A290',
          'Sony DSLR-A300',
          'Sony DSLR-A330',
          'Sony DSLR-A350',
          'Sony DSLR-A380',
          'Sony DSLR-A390',
          'Sony DSLR-A450',
          'Sony DSLR-A500',
          'Sony DSLR-A550',
          'Sony DSLR-A560',
          'Sony DSLR-A580',
          'Sony DSLR-A700',
          'Sony DSLR-A850',
          'Sony DSLR-A900',
          'Sony SLT-A33 ',
          'Sony SLT-A35 ',
          'Sony SLT-A37 ',
          'Sony SLT-A55  ',
          'Sony SLT-A55V',
          'Sony SLT-A57 ',
          'Sony SLT-A58 ',
          'Sony SLT-A65 ',
          'Sony SLT-A65V ',
          'Sony SLT-A77  ',
          'Sony SLT-A77V',
          'Sony SLT-A99 ',
          'Sony SLT-A99V',
          'Sony ILCA-68 ',
          'Sony ILCA-77M2 ',
          'Sony ILCA-99M2 ',
          'Sony NEX-3  ',
          'Sony NEX-3C',
          'Sony NEX-C3 ',
          'Sony NEX-F3 ',
          'Sony NEX-3N ',
          'Sony NEX-5  ',
          'Sony NEX-5C',
          'Sony NEX-5N ',
          'Sony NEX-5R ',
          'Sony NEX-5T ',
          'Sony NEX-6 ',
          'Sony NEX-7 ',
          'Sony ILCE-3000 ',
          'Sony ILCE-3500 ',
          'Sony ILCE-5000 ',
          'Sony ILCE-5100 ',
          'Sony ILCE-6000 ',
          'Sony ILCE-6300 ',
          'Sony ILCE-7 ',
          'Sony ILCE-7R ',
          'Sony ILCE-7S ',
          'Sony ILCE-7M2 ',
          'Sony ILCE-7RM2 ',
          'Sony ILCE-7SM2 ',
          'Sony ILCE-QX1 ',
          'Sony Cyber-shot DSC-V3 ',
          'Sony Cyber-shot DSC-F828 ',
          'Sony Cyber-shot DSC-R1 ',
          'Sony Cyber-shot DSC-RX1 ',
          'Sony Cyber-shot DSC-RX1R ',
          'Sony Cyber-shot DSC-RX10 ',
          'Sony Cyber-shot DSC-RX10M2 ',
          'Sony Cyber-shot DSC-RX100 ',
          'Sony Cyber-shot DSC-RX100M2 ',
          'Sony Cyber-shot DSC-RX100M3 ',
          'Sony Cyber-shot DSC-RX100M4 ',
          'Sony Handycam NEX-VG20   ',
          'Sony Handycam NEX-VG20E',
          'Sony Handycam NEX-VG30 ',
          'Sony Handycam NEX-VG30E',
          'Sony Handycam NEX-VG900 ',
          'Sony Handycam NEX-VG900E',
          'Sony XCD-SX710CR ',
          'Sony XCD-SX910CR '
        ]
      }
    ]
  },
  {
    category: {
      label: 'Lenses',
      value: 'LENSES'
    },
    items: [
      {
        brand: '',
        models: [
          'Fisheye (4mm - 14mm)',
          'Wide angle (14mm - 35mm)',
          'Standard (35mm - 85mm)',
          'Short telephoto (85mm - 135mm)',
          'Medium Telephoto (135mm+)',
          'Super Telephoto (300mm+)',
          'Macro (35mm - 200mm)',
          'Zoom - other',
          'Prime - other',
          'Tilt-shift',
          'Infrared',
          'Parfocal - other'
        ]
      }
    ]
  },
  {
    category: {
      label: 'Lights',
      value: 'LIGHTS'
    },
    items: [
      {
        brand: '',
        models: [
          'Shoe-mount flash',
          'Studio strobe',
          'Radio trigger',
          'Macro light - other',
          'Ringlight',
          'Speedlight',
          'Monolight - battery powered',
          'Monolight - mains powered',
          'Pack and head - battery powered',
          'Pack and head - mains powered',
          'Other'
        ]
      }
    ]
  },
  {
    category: {
      label: 'Modifiers',
      value: 'MODIFIERS'
    },
    items: [
      {
        brand: '',
        models: ['Diffusers', 'Reflectors ', 'Softboxes', 'Other']
      }
    ]
  }
];

const retrieveEquipmentsCategories = () => {
  return equipments.map(({ category }) => category);
};

const retrieveItems = ({ category }) => {
  const categoryData = equipments.find(equip => equip.category.value === category) || {};

  return categoryData.items || [];
};

/**
 * Creating an array of items with brand as key and models as value
 */
const mapItems = ({ items }) => {
  return items.map(({ brand, models }) => ({ [brand]: models }));
};

const equipUtils = {
  equipments,
  mapItems,
  retrieveEquipmentsCategories,
  retrieveItems
};

export default equipUtils;
