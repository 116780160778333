<template>
  <div class="manageEquipment">
    <div v-for="(cat, index) of equipmentCategories" :key="`${index}_category`" class="manageEquipment__equipment-box">
      <div class="align-middle justify-between">
        <p class="manageEquipment__equipment-title mb-0">{{ cat.label | firstUppercase }}</p>
        <BaseIcon icon="plus" :style="{ height: '15px', 'stroke-width': '4px', width: '15px' }" @click.native="selectedCategory = cat.value" />
      </div>
      <div :class="['manageEquipment__equipment-items', { 'mt-10': equipment[cat.value] }]">
        <div v-for="(item, index) of equipment[cat.value]" :key="index" class="manageEquipment__equipment-tag">
          <p>{{ item }}</p>
          <BaseIcon icon="close" :style="{ height: '11px', 'min-height': '11px', 'min-width': '11px', width: '11px' }" />
        </div>
      </div>
    </div>
    <AddEquipment
      :show="selectedCategory !== null"
      :category="selectedCategory"
      :items="items"
      :title="`${user.photographer_id} ${user.name}`"
      @dialog:closed="selectedCategory = null"
      @equipment:update="$emit('equipment:update', { selectedCategory, value: $event })"
    />
  </div>
</template>

<script>
// Base Components
import BaseIcon from '@base/BaseIcon.vue';

import AddEquipment from '@components/accounts/AddEquipment.vue';

// Utils
import equipUtils from '@utils/equipments';

/**
 * Component used to manage photographer equipment
 */
export default {
  name: 'ManageEquipment',
  components: {
    AddEquipment,
    BaseIcon
  },
  props: {
    /**
     * Pass the user equipment
     */
    equipment: { default: () => {}, type: Object },
    /**
     * User to be shown
     */
    user: { default: () => ({}), type: Object }
  },
  data() {
    return {
      equipmentCategories: equipUtils.retrieveEquipmentsCategories(),
      selectedCategory: null
    };
  },
  computed: {
    items() {
      const selectedEquipmentItems = this.equipment[this.selectedCategory] || [];
      return selectedEquipmentItems;
    }
  }
};
</script>

<style lang="scss" scoped>
.manageEquipment {
  &__equipment {
    &-box {
      background-color: var(--terziary-text-color);
      border-radius: 10px;
      padding: 11px;
      width: 316px;
      @include responsive($max: md) {
        width: 100%;
      }

      ~ .manageEquipment__equipment-box {
        margin-top: 10px;
      }
    }

    &-items {
      column-gap: 11px;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
      row-gap: 11px;
    }

    &-tag {
      align-items: center;
      background-color: var(--main-bg-color);
      border-radius: 10px;
      display: flex;
      justify-content: space-between;
      padding: 9px 12px;
      text-align: left;

      > p {
        @include inter-font($size: 14px);
        @include text-ellipsed;
        margin: 0;
      }
    }

    &-title {
      @include inter-font($size: 14px);
      text-align: left;
    }
  }
}
</style>
