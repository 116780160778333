<template>
  <q-dialog ref="dialog" @hide="handleClose">
    <q-card class="addEquipment__card">
      <q-card-section class="row items-center pa-0">
        <p class="addEquipment__title">{{ title || '-' }}</p>
        <q-space />
        <BaseIcon icon="close" v-close-popup />
      </q-card-section>

      <q-card-section class="pa-0 mt-36">
        <p class="addEquipment__heading mb-35">{{ category || '-' }}:</p>
        <div class="addEquipment__body">
          <section class="addEquipment__items">
            <div v-for="(item, index) of filteredEquipmentsItems" :key="index" class="addEquipment__item">
              {{ item }}
              <BaseIcon :icon="getIcon(item)" :style="{ height: '15px', 'stroke-width': '2px', width: '15px' }" @click.native="addEquipment(item)" />
              <hr data-theme="extra-light" class="addEquipment__items__hr mt-34" />
            </div>
          </section>
          <section class="addEquipment__equipments ml-40">
            <div v-for="(item, index) of allItems" :key="`${index}__new-item`" class="addEquipment__equipment">
              <p>{{ item }}</p>
              <BaseIcon
                v-if="newItems.includes(item)"
                icon="close"
                :stroke="getCssVariable('--secondary-text-color')"
                :style="{ height: '15px', 'stroke-width': '2px', width: '15px' }"
                @click.native="removeItem(item)"
              />
            </div>
          </section>
        </div>
        <hr data-theme="extra-light" class="hr mt-34" />
        <div class="addEquipment__footer mt-20">
          <BaseButton type="default" :disabled="isConfirmDisabled" :label="$t('CONFIRM')" @click.native="confirmNewItems" />
        </div>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
// Utils
import equipUtils from '@utils/equipments';

// Base Components
import BaseButton from '@base/BaseButton.vue';
import BaseIcon from '@base/BaseIcon.vue';

/**
 * Component that shows a dialog with functionality to select a new equipment for the user
 */
export default {
  name: 'AddEquipment',
  components: {
    BaseButton,
    BaseIcon
  },
  props: {
    /**
     * Pass the name of the macro category of the equiments
     */
    category: { default: '', type: String },
    /**
     * List of user equipment items
     */
    items: { default: () => [], type: Array },
    /**
     * Set to true to show the dialog
     */
    show: { default: false, type: Boolean },
    /**
     * Title
     */
    title: { default: '', type: String }
  },
  data() {
    return {
      newItems: []
    };
  },
  computed: {
    /**
     * Returnig the list of items
     */
    allItems() {
      return [...new Set([...this.items, ...this.newItems])];
    },
    /**
     * Return the equipments filtered by category
     */
    equipmentsItems() {
      const items = equipUtils.retrieveItems({ category: this.category }) || [];

      const mappedItems = items.map(({ models }) => models);
      return mappedItems.flat();
    },
    /**
     * Returning items that are not already being selected
     */
    filteredEquipmentsItems() {
      if (screen.width <= 760) return this.equipmentsItems;
      return this.equipmentsItems.filter(item => this.items.indexOf(item) === -1) || [];
    },
    /**
     * Returns true if no change has been made
     */
    isConfirmDisabled() {
      return this.newItems.length === 0;
    }
  },
  watch: {
    /**
     * When true show the dilaog, when false hide it
     */
    show() {
      if (this.show) {
        this.$refs.dialog.show();
      } else {
        this.$refs.dialog.hide();
      }
    }
  },
  methods: {
    /**
     * Method used to add a specialization
     */
    addEquipment(id) {
      if (screen.width <= 760) {
        this.newItems = this.updateArray({ array: this.newItems, element: id });
      } else this.newItems.push(id);
    },
    /**
     * Method used to emit an event to confirm the ne specializations, then closes the dialog
     */
    confirmNewItems() {
      this.$emit('equipment:update', this.allItems);
      this.$refs.dialog.hide();
    },
    /**
     * Method used to handle the closing of the dialog by emitting an event
     */
    handleClose() {
      this.$emit('dialog:closed');
    },
    /**
     * used to get the correct icon on responsive, based on the id of element
     */
    getIcon(id) {
      if (screen.width <= 760) return this.newItems.includes(id) ? 'check' : 'plus';
      return 'plus';
    },
    /**
     * Method used to remove a specialization from the list
     */
    removeItem(item) {
      this.newItems = this.updateArray({ array: this.newItems, element: item });
    }
  }
};
</script>

<style lang="scss">
.addEquipment {
  &__body {
    display: flex;

    > section {
      flex: 50%;
      @include responsive($max: md) {
        max-height: fit-content;
      }
    }
  }

  &__card {
    border-radius: 10px;
    padding: 35px 40px;
    min-width: 670px;
    @include responsive($max: md) {
      border-radius: 0;
      height: 100%;
      max-height: 100% !important;
      min-width: 100%;
    }
  }

  &__footer {
    align-items: center;
    display: flex;
    justify-content: flex-end;
  }

  &__heading {
    @include antonio-font($size: 23px);
  }

  &__item {
    @include inter-font($size: 14px);
    align-items: center;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    padding: 4px 12px;
    @include responsive($max: md) {
      align-items: flex-start;
      flex-direction: column;
      position: relative;
    }

    .baseIcon {
      display: none;
      @include responsive($max: md) {
        display: block;
        position: absolute;
        right: 0;
      }
    }

    &:hover {
      background-color: var(--main-bg-color);

      .baseIcon {
        display: block;
      }
    }
  }

  &__items {
    max-height: 158px;
    overflow-y: auto;
    @include responsive($max: md) {
      flex-direction: column;
      align-items: flex-start;
      position: relative;
    }
    &__hr {
      display: none;
      margin-top: 10px;
      @include responsive($max: md) {
        display: block;
      }
    }
  }

  &__equipment {
    align-items: center;
    display: flex;
    justify-content: space-between;

    > p {
      @include inter-font($size: 14px, $color: var(--secondary-text-color));
      margin-bottom: 0;
    }

    .baseIcon {
      display: none;
    }

    &:hover {
      .baseIcon {
        display: block;
      }
    }
  }

  &__equipments {
    background-color: var(--main-bg-color);
    border-radius: 10px;
    min-height: 158px;
    padding: 9px 13px;
    @include responsive($max: md) {
      display: none;
    }
  }

  &__title {
    @include antonio-font($size: 14px);
  }
}
.q-dialog__inner--minimized {
  @include responsive($max: md) {
    padding: 0 !important;
  }
}
</style>
